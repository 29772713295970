import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Image from "next/image";
import { bidsData } from "../../data/bids_data";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";

const BidsCarousel = () => {
  const { t } = useTranslation("home");
  const router = useRouter();

  const navigateToCourse = (routeName) => {
    const queryParams = {
      origin: routeName.split(" - ")[0],
      destination: routeName.split(" - ")[1],
    };
    const query = new URLSearchParams(queryParams).toString();
    const url = `/book?${query}`;
    router.push(url);
  };

  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar]}
        spaceBetween={30}
        slidesPerView="auto"
        loop={true}
        breakpoints={{
          240: {
            slidesPerView: 1,
          },
          565: {
            slidesPerView: 2,
          },
          1000: {
            slidesPerView: 3,
          },
          1100: {
            slidesPerView: 4,
          },
        }}
        navigation={{
          nextEl: ".bids-swiper-button-next",
          prevEl: ".bids-swiper-button-prev",
        }}
        className=" card-slider-4-columns !py-5"
      >
        {bidsData.map((item) => {
          const { id, image, routeName, price, alt } = item;

          return (
            <SwiperSlide className="text-white" key={id}>
              <article>
                <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg text-jacarta-500">
                  <figure>
                    <div className="w-full">
                      <Image
                        src={image}
                        height={230}
                        width={230}
                        alt={alt}
                        layout="responsive"
                        objectFit="cover"
                        className="rounded-[0.625rem] w-full"
                        loading="lazy"
                      />
                    </div>
                  </figure>
                  <div className="mt-4 flex items-center justify-between">
                    <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                      {routeName}
                    </span>
                    <span className="dark:border-jacarta-600 bg-green border-jacarta-100 flex items-center whitespace-nowrap rounded-md border py-1 px-2">
                      <span className=" text-jacarta-900 text-sm font-bold ">
                        {t("price-from")} {price} &#x20AC;
                      </span>
                    </span>
                  </div>

                  <div className="mt-8 flex items-center justify-center ">
                    <button
                      onClick={() => navigateToCourse(routeName)}
                      className="dark:text-accent-light text-accent-dark font-display text-sm font-bold"
                    >
                      {t("bids-book-now")}
                    </button>
                  </div>
                </div>
              </article>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* <!-- Slider Navigation --> */}
      <div className="group bids-swiper-button-prev swiper-button-prev shadow-white-volume absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-left-6 after:hidden">
        <MdKeyboardArrowLeft />
      </div>
      <div className="group bids-swiper-button-next swiper-button-next shadow-white-volume absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-right-6 after:hidden">
        <MdKeyboardArrowRight />
      </div>
    </>
  );
};

export default BidsCarousel;
