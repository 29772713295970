const bidsData = [
	{
		id: 1,
		image: '/images/courses/bansko.webp',
		routeName: 'Sofia Airport - Bansko',
		alt: 'sofia airport to borovets, sofia airport to bansko',
		price: 80,
	},
	{
		id: 2,
		image: '/images/courses/plovdiv.jpg',
		routeName: 'Sofia Airport - Plovdiv',
		alt: 'sofia to bansko transfer, sofia airport shuttle',
		price: 70,
	},
	{
		id: 3,
		image: '/images/courses/sofia.jpg',
		routeName: 'Sofia Airport - Sofia Center',
		alt: 'transfer from sofia airport to bansko, sofia airport to sunny beach',
		price: 30,
	},
	{
		id: 4,
		image: '/images/courses/burgas.jpg',
		routeName: 'Sofia Airport - Burgas',
		alt: 'sofia to bansko transfer, sofia to istanbul, sofia to plovdiv',
		price: 180,
	},
	{
		id: 5,
		image: '/images/courses/sunny-beach.jpg',
		routeName: 'Sofia Airport - Sunny Beach',
		alt: 'sofia to sunny beach, sofia to bucharest, sofia to varna, sofia to skopje',
		price: 200,
	},
	{
		id: 6,
		image: '/images/courses/borovets.jpg',
		routeName: 'Sofia Airport - Borovets',
		alt: 'transfer from sofia to bansko, transfer from bansko to sofia',
		price: 40,
	},
	{
		id: 7,
		image: '/images/courses/pamporovo.jpg',
		routeName: 'Sofia Airport - Pamporovo',
		alt: 'sofia to belgrade, sofia to thessaloniki, transfer from sofia to borovets',
		price: 110,
	},
	{
		id: 8,
		image: '/images/courses/rila-lakes.jpg',
		routeName: 'Sofia Airport - 7 Rila Lakes',
		alt: 'sofia to skopje, sofia to plovdiv, sofia to istanbul',
		price: 50,
	},
];

export { bidsData };
